import i18n from '@/plugins/vue-i18n'

export default [
  {
    path: '/monthly-user-attendance',
    name: 'monthlyUserAttendance',
    component: () => import('./pages/monthlyUserAttendance.vue'),
    meta: {
      authenticatedOnly: true,
      hasRole: true,
      breadcrumb: [
        {
          text: i18n.t('navigation.monthlyUserAttendance'),
          to: '/monthly-user-attendance'
        }
      ]
    }
  }
]
