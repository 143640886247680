import i18n from '@/plugins/vue-i18n'

export default [
  {
    path: '/',
    alias: '/dashboard',
    name: 'dashboard',
    component: () => import('./pages/Dashboard.vue'),
    meta: {
      authenticatedOnly: true,
      breadcrumb: [
        {
          text: i18n.t('navigation.dashboard'),
          to: '/'
        }
      ]
    }
  }
]
