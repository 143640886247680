import * as TokenService from './token.service'

const loginRoute = 'login'
const dashboardRoute = '/'
const backofficeRoute = 'backoffice'
const monthlyUserAttendanceRoute = 'monthly-user-attendance'

const ensureAuthenticated = (store, to, from, next) => {
  const { isAuthenticated } = store.getters

  const canAccessRoute = to.name === loginRoute ? '' : to.name
  const loginCallbackRoute = canAccessRoute
    ? { name: loginRoute }
    : { name: loginRoute, query: { redirect: to.fullPath } }

  if (to.matched.some(record => record.meta.authenticatedOnly) && !isAuthenticated) {
    return next(loginCallbackRoute)
  }

  return next()
}

const ensureUnauthenticated = (store, to, from, next) => {
  const { isAuthenticated } = store.getters

  if (to.matched.some(record => record.meta.unauthenticatedOnly) && isAuthenticated) {
    return next(from.matched.length ? false : { name: dashboardRoute })
  }

  return next()
}
const hasRole = (store, to, from, next) => {
  const { userRole } = store.getters

  if (
    userRole !== 'admin' &&
    userRole !== 'backoffice' &&
    (to.name === backofficeRoute || to.name === monthlyUserAttendanceRoute)
  ) {
    return next({ path: dashboardRoute })
  }

  return next()
}

export default {
  loginRoute,
  dashboardRoute,
  getAuthToken: TokenService.getAuthToken,
  setAuthToken: TokenService.setAuthToken,
  removeAuthToken: TokenService.removeAuthToken,
  getAuthTokenExpiry: TokenService.getAuthTokenExpiry,
  setAuthTokenExpiry: TokenService.setAuthTokenExpiry,
  removeAuthTokenExpiry: TokenService.removeAuthTokenExpiry,
  ensureAuthenticated,
  ensureUnauthenticated,
  hasRole
}
