import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import AuthService from '@/core/auth/services/auth.service'

import authRoutes from '@/core/auth/routes'
import dashboardRoutes from '@/modules/dashboard/routes'
import vacationRoutes from '@/modules/vacation/routes'
import overtimeRoutes from '@/modules/overtime/routes'
import sickDaysRoutes from '@/modules/sick_days/routes'
import monthlyUserAttendanceRoutes from '@/modules/monthly_user_attendance/routes'
import challengesRoutes from '@/modules/challenges/routes'
import backofficeRoutes from '@/modules/backoffice/routes'
import libraryRoutes from '@/modules/library/routes'
import calendarRoutes from '@/modules/calendar/routes'

Vue.use(VueRouter)

const routes = [
  ...authRoutes,
  ...dashboardRoutes,
  ...overtimeRoutes,
  ...vacationRoutes,
  ...sickDaysRoutes,
  ...monthlyUserAttendanceRoutes,
  ...challengesRoutes,
  ...backofficeRoutes,
  ...libraryRoutes,
  ...calendarRoutes
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => AuthService.ensureAuthenticated(store, to, from, next))
router.beforeEach((to, from, next) => AuthService.ensureUnauthenticated(store, to, from, next))
router.beforeEach((to, from, next) => AuthService.hasRole(store, to, from, next))

export default router
