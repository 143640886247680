import Vue from 'vue'
import VueI18n from 'vue-i18n'

import StorageService from '@/shared/services/storage.service'
import * as messages from '@/i18n'

const currentLocale = StorageService.getItem(process.env.VUE_APP_LS_LOCALE_KEY) || process.env.VUE_APP_DEFAULT_LOCALE

Vue.use(VueI18n)

export default new VueI18n({
  locale: currentLocale,
  fallbackLocale: process.env.VUE_APP_DEFAULT_LOCALE,
  messages
})
