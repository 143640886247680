import i18n from '@/plugins/vue-i18n'

export default [
  {
    path: '/sick_days',
    name: 'sick_days',
    component: () => import('./pages/SickDays.vue'),
    meta: {
      authenticatedOnly: true,
      breadcrumb: [
        {
          text: i18n.t('navigation.sick_days'),
          to: '/sick_days'
        }
      ]
    }
  }
]
