/**
 * @module Storage
 * @description This will be responsible for storing data in the application
 * either in localStorage or sessionStorage
 */
class Storage {
  constructor(storage) {
    this.storage = storage || window.localStorage

    if (!this.isSupported()) {
      throw new Error('Storage is not supported by the browser!')
    }
  }

  setItem(key, value) {
    this.storage.setItem(key, value)
  }

  getItem(key) {
    return this.storage.getItem(key)
  }

  removeItem(key) {
    this.storage.removeItem(key)
  }

  clear() {
    this.storage.clear()
  }

  /**
   * @description Check if the current storage is supported by the browser
   * @returns {boolean} is supported
   * */
  isSupported() {
    return this.storage
  }
}

/**
 * @description Create storage instance
 * @param {Object} storage localStorage as default or sessionStorage
 */
export default new Storage()
