<template>
  <div class="timer">
    <div class="timer__clock">
      <span>{{ formattedElapsedTime }}</span>
    </div>
    <div class="timer__actions">
      <v-icon @click="handle" color="secondary" title="Play">{{ !started ? 'mdi-play' : 'mdi-pause' }} </v-icon>
      <v-icon @click.stop="stop" color="secondary" title="Stop">mdi-stop</v-icon>
      <v-icon @click="reset" color="secondary" title="Reset">mdi-restart</v-icon>
    </div>
    <v-dialog v-model="dialog" persistent max-width="450">
      <v-card>
        <v-form ref="form" v-model="valid">
          <v-card-title class="text-h5"> Redmine</v-card-title>
          <v-card-text>
            <v-text-field
              name="issue_id"
              v-model="issue_id"
              :label="$t('stopwatch.issue_number')"
              :rules="requiredRule"
              required
              @click="clear()"
              @blur="validateIssue"
            ></v-text-field>
            <v-select
              :items="redmineData"
              item-text="project"
              item-value="id"
              v-model="issue_id"
              :label="$t('stopwatch.issue_number')"
            ></v-select>
            <v-select
              v-model="activity_id"
              name="activity_id"
              :items="activityOptions"
              :rules="requiredRule"
              :label="$t('stopwatch.select_activity')"
              required
            ></v-select>
            <v-textarea name="comments" v-model="comments" :label="$t('stopwatch.comment')"></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="plain" plain @click="close"> {{ $t('button.cancel') }}</v-btn>
            <v-btn color="primary" @click="submit" :loading="isLoading"> {{ $t('button.save') }} </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import httpClient from '@/core/api/services/api.service'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Stopwatch',
  data() {
    return {
      valid: false,
      issueIsValid: false,
      started: false,
      redmineData: [],
      elapsedTime: 0,
      dialog: false,
      timer: undefined,
      issue_id: null,
      activity_id: null,
      comments: '',
      isLoading: false,
      activityOptions: [
        { value: '14', text: 'Adwords' },
        { value: '34', text: 'Copywriting' },
        { value: '12', text: 'Design' },
        { value: '9', text: 'Development' },
        { value: '20', text: 'Documentation' },
        { value: '15', text: 'Meeting' },
        { value: '8', text: 'Planning' },
        { value: '22', text: 'PM' },
        { value: '17', text: 'QA' },
        { value: '21', text: 'Research' },
        { value: '16', text: 'Sitebuild' },
        { value: '11', text: 'Support' },
        { value: '19', text: 'Sysadmin' },
        { value: '10', text: 'Testing' },
        { value: '18', text: 'UX' }
      ]
    }
  },
  created() {
    this.getData()
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
    formattedElapsedTime() {
      const date = new Date(null)
      date.setSeconds(this.elapsedTime / 1000)
      const utc = date.toUTCString()
      return utc.substr(utc.indexOf(':') - 2, 8)
    },
    requiredRule() {
      return [v => !!v || this.$t('global.error_message')]
    }
  },
  methods: {
    clear() {
      this.issue_id = ''
    },
    validate() {
      this.$refs.form.validate()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    handle() {
      return !this.started ? this.start() : this.pause()
    },
    start() {
      this.started = true
      this.timer = setInterval(() => {
        this.elapsedTime += 1000
      }, 1000)
    },
    pause() {
      clearInterval(this.timer)
      this.started = false
      this.timer = null
    },
    stop() {
      if (this.elapsedTime > 0) {
        clearInterval(this.timer)
        this.dialog = true
        this.timer = null
        this.started = false
      }
    },
    reset() {
      clearInterval(this.timer)
      this.timer = null
      this.started = false
      this.elapsedTime = 0
    },
    close() {
      this.resetValidation()
      this.clearForm()
      this.dialog = false
    },
    clearForm() {
      this.issue_id = null
      this.activity_id = null
      this.comments = ''
    },
    async validateIssue() {
      const { data } = await httpClient.get(`redmine/${this.issue_id}`)
      if (data.issues.length !== 0) {
        this.valid = true
      } else {
        alert('Nem jó az issue száma')
      }
    },
    async submit() {
      this.validate()
      if (!this.valid) return
      try {
        this.isLoading = true
        await httpClient.save('redmine', {
          issue_id: this.issue_id,
          activity_id: this.activity_id,

          comments: this.comments,
          hours: this.elapsedTime > 5000 ? Math.ceil(this.elapsedTime / 1000 / 60 / 15) * 0.25 : 0,
          spent_on: moment().format('YYYY-MM-DD')
        })

        this.clearForm()
        this.reset()
        this.close()
      } catch (error) {
        this.$noty.error(this.$t('notifications.error_post'))
        throw new Error(error)
      } finally {
        this.isLoading = false
      }
    },
    async getData() {
      try {
        const { data } = await httpClient.get(`redmine`)
        this.redmineData = data.issues.map(item => ({
          project: item.subject,
          priority: item.priority.id,
          priorityName: item.priority.name,
          id: item.id
        }))
      } catch (error) {
        this.$noty.error(this.$t('notifications.error_get'))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.timer {
  display: flex;
  margin-right: 30px;

  &__actions {
    min-width: 75px;
  }

  &__clock {
    font-size: 18px;
    margin-right: 10px;
  }
}
</style>
