<template>
  <v-list class="app-main-navigation-list d-flex">
    <div class="app-main-navigation-list-items">
      <div class="app-logo py-2 px-3">
        <router-link to="/">
          <img src="@/assets/images/logo.svg" alt="mY-Dash" class="logo" />
        </router-link>
      </div>
      <v-list-item-group v-if="user" color="primary">
        <v-list-item v-for="menuItem in getNavItems" :key="menuItem.id" :to="menuItem.link">
          <v-list-item-icon>
            <v-icon v-text="menuItem.icon" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ menuItem.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </div>
  </v-list>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'AppMainNavigation',
  data() {
    return {
      menuItems: [
        {
          id: 1,
          name: this.$t('navigation.dashboard'),
          icon: 'mdi-view-dashboard',
          link: '/'
        },
        {
          id: 2,
          name: this.$t('navigation.vacations'),
          icon: 'mdi-calendar-account',
          link: {
            name: 'vacation'
          }
        },
        {
          id: 3,
          name: this.$t('navigation.overtime'),
          icon: 'mdi-account-clock',
          link: {
            name: 'overtime'
          }
        },
        {
          id: 4,
          name: this.$t('navigation.sick_days'),
          icon: 'mdi-emoticon-sick-outline',
          link: {
            name: 'sick_days'
          }
        },
        {
          id: 5,
          name: this.$t('navigation.monthlyUserAttendance'),
          icon: 'mdi-google-spreadsheet',
          link: {
            name: 'monthlyUserAttendance'
          }
        },
        {
          id: 6,
          name: this.$t('navigation.backoffice'),
          icon: 'mdi-calculator',
          link: {
            name: 'backoffice'
          }
        },
        {
          id: 7,
          name: this.$t('navigation.challenges'),
          icon: 'mdi-shield-star-outline',
          link: {
            name: 'challenges'
          }
        },
        {
          id: 8,
          name: this.$t('navigation.calendar'),
          icon: 'mdi-calendar-month',
          link: {
            name: 'calendar'
          }
        },
        {
          id: 9,
          name: this.$t('navigation.library'),
          icon: 'mdi-bookshelf',
          link: {
            name: 'library'
          }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),

    getNavItems() {
      if (this.user.role !== 'backoffice' && this.user.role !== 'admin') {
        return this.menuItems.filter(
          item => item.link.name !== 'backoffice' && item.link.name !== 'monthlyUserAttendance'
        )
      }
      if (this.user.role !== 'admin') {
        return this.menuItems.filter(item => item.link.name !== 'admin')
      }
      return this.menuItems
    }
  }
}
</script>
<style lang="scss" scoped>
.app-main-navigation-list {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .v-list-item {
    .v-list-item__icon {
      margin-right: 15px;
    }
  }

  .v-list-item {
    &:last-child {
      flex: 0;

      .v-list-item__icon {
        align-self: center;
        margin-right: 15px;
      }
    }
  }

  .app-logo {
    img {
      height: 32px;
    }
  }
}
</style>
