import Vue from 'vue'
import httpClient from '@/core/api/services/api.service'

import { VueMasonryPlugin } from 'vue-masonry'
import i18n from '@/plugins/vue-i18n'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/plugins/vue-progressbar'
import '@/plugins/vue-noty'

import '@/shared/filters/capitalize'
import vuetify from './plugins/vuetify'

import '@/plugins/sentry'

Vue.config.productionTip = false
Vue.use(VueMasonryPlugin)

const app = new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

httpClient.init(app)
