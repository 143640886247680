<template>
  <div class="app-breadcrumbs">
    <v-breadcrumbs :items="breadcrumbList" large></v-breadcrumbs>
  </div>
</template>
<script>
export default {
  name: 'AppBreadcrumbs',
  data() {
    return {
      breadcrumbList: []
    }
  },
  watch: {
    $route() {
      this.updateBreadcrumbList()
    }
  },
  methods: {
    updateBreadcrumbList() {
      this.breadcrumbList = this.$route.meta.breadcrumb
    }
  },
  mounted() {
    this.updateBreadcrumbList()
  }
}
</script>
