const state = {
  hiddenWidgets: [],
  isDarkTheme: window.matchMedia('(prefers-color-scheme: dark)').matches,
  calendarFormat: 'month',
  itemsNumber: -1
}

const getters = {}

const actions = {}

const mutations = {
  themeHandler(state, item) {
    state.isDarkTheme = item
  },
  addWidget(state, item) {
    state.hiddenWidgets.push(item)
  },
  removeWidget(state, item) {
    state.hiddenWidgets.splice(state.hiddenWidgets.indexOf(item), 1)
  },
  addItemsNumber(state, item) {
    state.itemsNumber = item
  },
  calendar(state, item) {
    state.calendarFormat = item
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
