import StorageService from '@/shared/services/storage.service'

const authTokenLocalStorageKey = 'authToken'
const authTokenExpiry = 'userAuthTokenExpiry'

const getAuthToken = () => StorageService.getItem(authTokenLocalStorageKey) || ''
const getAuthTokenExpiry = () => StorageService.getItem(authTokenExpiry)

const setAuthToken = token => {
  StorageService.setItem(authTokenLocalStorageKey, token)
}

const setAuthTokenExpiry = date => {
  StorageService.setItem(authTokenExpiry, date)
}

const removeAuthToken = () => {
  StorageService.removeItem(authTokenLocalStorageKey)
}

const removeAuthTokenExpiry = () => {
  StorageService.removeItem(authTokenExpiry)
}

export { getAuthToken, getAuthTokenExpiry, setAuthToken, setAuthTokenExpiry, removeAuthToken, removeAuthTokenExpiry }
