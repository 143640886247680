import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from '@/core/auth/auth.store'
import dashboard from '@/modules/dashboard/dashboard.store'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    auth,
    dashboard
  },
  plugins: [
    createPersistedState({
      paths: [
        'auth.user',
        'dashboard.hiddenWidgets',
        'dashboard.itemsNumber',
        'dashboard.isDarkTheme',
        'dashboard.calendarFormat'
      ]
    })
  ],
  strict: debug
})
