export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('./pages/Login.vue'),
    meta: {
      unauthenticatedOnly: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('./pages/Registration.vue'),
    meta: {
      unauthenticatedOnly: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('./pages/Profile.vue'),
    meta: {
      authenticatedOnly: true,
      breadcrumb: [
        {
          text: 'Profil',
          to: 'profile'
        }
      ]
    }
  }
]
