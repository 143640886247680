import i18n from '@/plugins/vue-i18n'

export default [
  {
    path: '/overtime',
    name: 'overtime',
    component: () => import('./pages/Overtime.vue'),
    meta: {
      authenticatedOnly: true,
      breadcrumb: [
        {
          text: i18n.t('navigation.overtime'),
          to: {
            name: 'overtime'
          }
        }
      ]
    }
  }
]
