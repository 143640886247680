export const AUTH_REQUEST = 'auth_request'
export const AUTH_LOGOUT = 'auth_logout'
export const AUTH_SUCCESS = 'auth_success'
export const AUTH_ERROR = 'auth_error'
export const SOCIAL_AUTH_REQUEST = 'social_auth_request'

export const GET_USER_REQUEST = 'get_user_request'
export const GET_USER_SUCCESS = 'get_user_success'
export const GET_USER_ERROR = 'get_user_error'
export const UPDATE_USER_REQUEST = 'update_user_request'
export const SET_USER = 'set_user'

export const REGISTRATION_REQUEST = 'registration_request'
export const REGISTRATION_SET_USER = 'registration_set_user'
export const REGISTRATION_SUCCESS = 'registration_success'
export const REGISTRATION_ERROR = 'registration_error'

export const GET_DASHBOARD_REQUEST = 'get_dashboard_request'
export const GET_DASHBOARD_ERROR = 'get_dashboard_error'
export const SET_DASHBOARD = 'set_dashboard'
