import i18n from '@/plugins/vue-i18n'

export default [
  {
    path: '/backoffice',
    name: 'backoffice',
    component: () => import('./pages/Backoffice.vue'),
    meta: {
      authenticatedOnly: true,
      hasRole: true,
      breadcrumb: [
        {
          text: i18n.t('navigation.backoffice'),
          to: {
            name: 'backoffice'
          }
        }
      ]
    }
  }
]
