import i18n from '@/plugins/vue-i18n'

export default [
  {
    path: '/library',
    name: 'library',
    component: () => import('./pages/Library.vue'),
    meta: {
      authenticatedOnly: true,
      breadcrumb: [
        {
          text: i18n.t('navigation.library'),
          to: '/library'
        }
      ]
    }
  }
]
