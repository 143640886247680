<template>
  <v-app>
    <!-- APP BAR   -->
    <v-app-bar app elevation="1" v-if="isAuthenticated && !isLoading">
      <v-app-bar-nav-icon v-if="isMobile" @click.native.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <app-top-navigation />
    </v-app-bar>
    <!-- END APP BAR   -->
    <!-- APP MAIN NAV MOBIL  -->
    <v-navigation-drawer v-if="isMobile && isAuthenticated && !isLoading" v-model="drawer" fixed>
      <app-main-navigation />
    </v-navigation-drawer>
    <!-- END APP MAIN NAV MOBIL  -->
    <!-- APP MAIN NAV DESKTOP  -->
    <v-navigation-drawer v-if="!isMobile && isAuthenticated && !isLoading" app permanent mini-variant expand-on-hover>
      <app-main-navigation />
    </v-navigation-drawer>
    <!-- END APP MAIN NAV DESKTOP  -->
    <v-main v-if="!isLoading">
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>

    <app-skeleton-loader v-else />

    <vue-progress-bar />
  </v-app>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import AppMainNavigation from '@/modules/navigation/components/AppMainNavigation'
import AppTopNavigation from '@/modules/navigation/components/AppTopNavigation'
import AppSkeletonLoader from '@/shared/components/AppSkeletonLoader'

export default {
  name: 'App',
  data: () => ({
    drawer: false
  }),
  components: {
    AppMainNavigation,
    AppTopNavigation,
    AppSkeletonLoader
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    ...mapState({
      status: state => state.auth.userStatus,
      isDarkTheme: state => state.dashboard.isDarkTheme
    }),
    isLoading() {
      return this.status === 'loading'
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  created() {
    this.$vuetify.theme.dark = this.isDarkTheme
    this.$store.commit('themeHandler', this.$vuetify.theme.dark)
  }
}
</script>
<style lang="scss">
@import '@/assets/styles/main.scss';
</style>
