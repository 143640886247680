<template>
  <v-row class="align-center" no-gutters>
    <v-col cols="auto" sm="3">
      <app-breadcrumbs></app-breadcrumbs>
    </v-col>
    <v-col cols="auto" sm="9" class="d-flex ml-auto align-center justify-end flex-row">
      <Stopwatch class="d-none d-sm-flex" v-if="user && user.redmineApiKey" />
      <v-icon @click="themeHandler">mdi-theme-light-dark</v-icon>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on" v-if="user">
            <v-img class="rounded mr-2" left :src="getGravatar()" />
            <div>
              {{ user.firstName }} <span class="d-none d-md-inline-block">{{ user.lastName }}</span>
            </div>
            <v-icon dark color="grey lighten-1" right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="user && user.role">
            <v-list-item-content>
              <v-list-item-title>{{ userRole }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'profile' }">
            <v-list-item-content>
              <v-list-item-title>{{ $t('navigation.account.editProfile') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="logout" class="g_id_signout">
            <v-list-item-content>
              <v-list-item-title>{{ $t('auth.logout') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>
<script>
// eslint-disable-next-line
/* global google */

import { mapState } from 'vuex'

import { AUTH_LOGOUT } from '@/types'

import AuthService from '@/core/auth/services/auth.service'
import httpClient from '@/core/api/services/api.service'

import AppBreadcrumbs from '@/shared/components/AppBreadcrumbs.vue'
import Stopwatch from '@/modules/logger/components/Stopwatch.vue'
import md5 from 'md5'

export default {
  name: 'AppTopNavigation',
  components: {
    AppBreadcrumbs,
    Stopwatch
  },
  computed: {
    ...mapState({
      status: state => state.navigation.status,
      user: state => state.auth.user
    }),
    userRole() {
      return this.user.role.charAt(0).toUpperCase() + this.user.role.slice(1)
    }
  },
  methods: {
    async logout() {
      await httpClient.save('logout')
      this.$store.commit(AUTH_LOGOUT)
      this.$router.push({ name: AuthService.loginRoute })
    },
    getGravatar() {
      const defaultGravatar = `https://www.gravatar.com/avatar/00000000000000000000000000000000?s=21&d=mp`

      if (!this.user.email) {
        return defaultGravatar
      }

      const address = this.user.email.trim().toLowerCase()
      const hash = md5(address)

      return `https://www.gravatar.com/avatar/${hash}?s=21&d=mp`
    },
    themeHandler() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.$store.commit('themeHandler', this.$vuetify.theme.dark)
    }
  }
}
</script>
