import moment from 'moment'

import httpClient from '@/core/api/services/api.service'
import AuthService from '@/core/auth/services/auth.service'

import {
  AUTH_REQUEST,
  AUTH_LOGOUT,
  AUTH_SUCCESS,
  AUTH_ERROR,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  UPDATE_USER_REQUEST,
  REGISTRATION_REQUEST,
  REGISTRATION_SUCCESS,
  REGISTRATION_ERROR,
  SOCIAL_AUTH_REQUEST
} from '@/types'

const state = {
  authToken: AuthService.getAuthToken(),
  authTokenExpiry: AuthService.getAuthTokenExpiry(),
  status: null,
  userStatus: null,
  user: null
}

const getters = {
  isAuthenticated: state => !!state.authToken && moment().isBefore(state.authTokenExpiry),
  userRole: state => !!state.user && state.user.role
}

const actions = {
  async [AUTH_REQUEST]({ commit }, user) {
    commit(AUTH_REQUEST)

    try {
      const { data } = await httpClient.save('login', user)
      commit(AUTH_SUCCESS, data)
    } catch (error) {
      commit(AUTH_ERROR, error)
      Promise.reject(error)
    }
  },
  async [SOCIAL_AUTH_REQUEST]({ commit }, { provider, token }) {
    commit(AUTH_REQUEST)

    try {
      const { data } = await httpClient.save(`login/${provider}`, { token })
      commit(AUTH_SUCCESS, data)
      return Promise.resolve(data)
    } catch (error) {
      commit(AUTH_ERROR, error)
      return Promise.reject(error)
    }
  },
  async [GET_USER_REQUEST]({ commit }) {
    commit(GET_USER_REQUEST)

    try {
      const { data } = await httpClient.get('users/me')
      commit(GET_USER_SUCCESS, data)
    } catch (error) {
      commit(GET_USER_ERROR, error)
      Promise.reject(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  async [UPDATE_USER_REQUEST]({ commit }, user) {
    try {
      await httpClient.patch('users', user.id, user)
    } catch (error) {
      Promise.reject(error)
    }
  },
  async [REGISTRATION_REQUEST]({ commit }, user) {
    commit(REGISTRATION_REQUEST)

    try {
      await httpClient.save('users/register', user)
      commit(REGISTRATION_SUCCESS)
    } catch (error) {
      commit(REGISTRATION_ERROR, error)
      Promise.reject(error)
    }
  }
}

const mutations = {
  [AUTH_REQUEST](state) {
    state.status = 'loading'
  },
  [AUTH_SUCCESS](state, { accessToken, expiresAt }) {
    AuthService.setAuthToken(accessToken)
    AuthService.setAuthTokenExpiry(expiresAt)
    httpClient.setAuthorizationHeader(accessToken)
    state.authToken = accessToken
    state.authTokenExpiry = expiresAt
    state.status = 'success'
  },
  [AUTH_ERROR](state) {
    state.status = 'error'
  },
  [AUTH_LOGOUT](state) {
    AuthService.removeAuthToken()
    AuthService.removeAuthTokenExpiry()
    state.user = null
    state.authToken = ''
    state.status = null
    state.userStatus = null
  },
  [GET_USER_REQUEST](state) {
    state.userStatus = 'loading'
  },
  [GET_USER_SUCCESS](state, user) {
    state.userStatus = 'success'
    state.user = user
  },
  [GET_USER_ERROR](state) {
    state.userStatus = 'error'
  },
  [REGISTRATION_REQUEST](state) {
    state.registrationStatus = 'loading'
  },
  [REGISTRATION_SUCCESS](state) {
    state.registrationStatus = 'success'
  },
  [REGISTRATION_ERROR](state) {
    state.registrationStatus = 'error'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
