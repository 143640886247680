import axios from 'axios'
import AuthService from '@/core/auth/services/auth.service'

const httpClient = axios.create({ baseURL: process.env.VUE_APP_API_BASE_URL })

const token = AuthService.getAuthToken()

const setAuthorizationHeader = token => {
  httpClient.defaults.headers.Authorization = `Bearer ${token}`
}

if (token) {
  setAuthorizationHeader(token)
}

const collectionResponseHandler = async response =>
  Promise.resolve({
    data: response.data,
    raw: response
  })

const singularResponseHandler = async response =>
  Promise.resolve({
    data: response.data,
    raw: response
  })

const generalErrorHandler = error => {
  console.error(`[mY-Dash] ApiService ${error}`)
  return Promise.reject({
    errStatus: error.response.status,
    errData: error.response.data,
    errRaw: error
  })
}

const onRequestStart = (config, app) => {
  app.$Progress.start()
  return config
}

const onResponseFinish = (response, app) => {
  app.$Progress.finish()
  return response
}

export default {
  setAuthorizationHeader,
  init(app) {
    httpClient.interceptors.request.use(config => onRequestStart(config, app))
    httpClient.interceptors.response.use(response => onResponseFinish(response, app))
  },
  request(method, resource, params, responseType) {
    return httpClient
      .request({
        method,
        url: `${resource}`,
        params,
        responseType
      })
      .catch(generalErrorHandler)
  },
  get(resource, slug = '', params) {
    return httpClient
      .request({
        method: 'get',
        url: slug ? `${resource}/${slug}` : `${resource}`,
        params
      })
      .then(singularResponseHandler)
      .catch(generalErrorHandler)
  },
  query(resource, params) {
    return httpClient
      .request({
        method: 'get',
        url: `${resource}`,
        params
      })
      .then(collectionResponseHandler)
      .catch(generalErrorHandler)
  },
  save(resource, data, params) {
    return httpClient
      .request({
        method: 'post',
        url: `${resource}`,
        data,
        params
      })
      .then(singularResponseHandler)
      .catch(generalErrorHandler)
  },
  update(resource, slug = '', data, params) {
    return httpClient
      .request({
        method: 'put',
        url: `${resource}/${slug}`,
        data,
        params
      })
      .then(singularResponseHandler)
      .catch(generalErrorHandler)
  },
  patch(resource, slug = '', data, params) {
    return httpClient
      .request({
        method: 'patch',
        url: `${resource}/${slug}`,
        data,
        params
      })
      .then(singularResponseHandler)
      .catch(generalErrorHandler)
  },
  delete(resource, slug = '') {
    return httpClient
      .request({
        method: 'delete',
        url: `${resource}/${slug}`
      })
      .then(singularResponseHandler)
      .catch(generalErrorHandler)
  },
  upload(resource, slug = '', file, type, params) {
    return httpClient
      .request({
        method: 'post',
        url: `${resource}/${slug}`,
        data: file,
        headers: {
          'Content-Type': type
        },
        params
      })
      .then(singularResponseHandler)
      .catch(generalErrorHandler)
  }
}
