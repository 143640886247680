import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import Vue from 'vue'
import router from '@/router'

Sentry.init({
  Vue,
  dsn: 'https://a563fed2354146609c10759f8a60a261@o149514.ingest.sentry.io/4504332071993344',
  // beforeSend(event) {
  //   // Check if it is an exception, and if so, show the report dialog
  //   if (event.exception) {
  //     Sentry.showReportDialog({ eventId: event.event_id })
  //   }
  //   return event
  // },
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ['localhost', 'my-site-url.com', /^\//]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})
