import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        primary: colors.teal,
        secondary: colors.shades.black
      },
      dark: {
        primary: colors.teal,
        secondary: colors.shades.white
      }
    },
    dark: window.matchMedia('(prefers-color-scheme: dark)').matches
  }
})
